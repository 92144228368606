import styles from './FormInputText.module.css'
import React from 'react';

export default function PasswordInputText({className, label, name, ...other}) {
	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.InputText_Label}`} htmlFor={name}>
			{label}
		</label>
		<div className={`${styles.InputText_Wrapper}`}>
			<input className={`${styles.InputText_Input}`} type="password" name={name} {...other}/>
		</div>
	</div>
}
