export default class Auth {

    public async isAuthenticated() {
        return localStorage.getItem('access_token');
    };

    public static logout = async () => {
        localStorage.removeItem('access_token');
    };
}
