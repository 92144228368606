import React from 'react';
import {Router} from "@reach/router";
import SignIn from "./Pages/SignIn";
import Secure from "./Secure.js";

export default function Home({ auth }) {

    return <Router primary={true}>
		<Secure path='/' auth={auth} default>
			<SignIn path="/sign-in" auth={auth} />
		</Secure>
	</Router>
};
