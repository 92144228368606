import React, {useEffect} from "react";
import {navigate, Router, useLocation} from "@reach/router";
import Licences from "./Pages/Licences";
import {StoreProvider} from "../context/StoreProvider.js";
import AddLicence from "./Pages/AddLicence";

export default function Secure({auth, children}) {
    const location = useLocation();

    useEffect(() => {

        (async () => {
            if (!await auth.isAuthenticated()) {
                await navigate("/sign-in");
            } else {
                if (location.pathname === '/') {
                    await navigate('/licences');
                } else {
                    await navigate(location.pathname);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {children}
        <StoreProvider >
            <Router primary={true}>
                <Licences path="/licences" auth={auth}/>
                <AddLicence path='/new-licence' />
            </Router>
        </StoreProvider>
    </>

}
