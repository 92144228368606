import React from 'react';
import {Router} from '@reach/router';
import Home from './pages/Home';
import Auth from "./modules/Auth";

const NotFound = () => <span>Sorry, page not found</span>;

function App() {
    let auth = new Auth();

    return (
        <Router>
            <NotFound default={true}/>
            <Home path='/*' auth={auth}/>
        </Router>
    );
}

export default App;
