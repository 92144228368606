import {SdkConfig} from "./SdkConfig";
import {httpPost} from "../HttpClient";

export class UserClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/login`;
    }

    async login(username: string, password: string) {
        let response = await httpPost(this.url, {
            username: username,
            password: password
        });
        return response.status === 200;
    }
}
