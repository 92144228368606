import React from 'react';
import styles from './SignIn.module.css'
import useForm from '../../hooks/useForm';
import {Sdk} from "../../modules/sdk/Sdk";
import ButtonPrimary from "../Symbols/ButtonPrimary";
import {navigate} from "@reach/router";
import FormInputText from "../Symbols/FormInputText";
import PasswordInputText from "../Symbols/PasswordInputText";

export default function SignIn() {
	const { values, errors, setErrors, handleChange, handleSubmit } = useForm(onFormSubmit);

	async function onFormSubmit() {
		if (await Sdk.userClient.login(values.username, values.password)) {
			await navigate('/licences');
		} else {
			values.username = '';
			values.password = '';
			setErrors({
				message : "Invalid username or password"
			});
		}
	}

	return <>
		<div className={`${styles.Wrapper} text-center`}>
			<main className={`${styles.FormSignIn}`}>
				<form className={`${styles.FormSignIn}`} onSubmit={handleSubmit}>
					<img className={`mb-4`} src="/icon.png" alt="" width="72" height="72"/>
					<h1 className={`h3 mb-3 fw-normal`}>Sign in</h1>
					<FormInputText name="username" placeholder="Username" onChange={handleChange} value={values.username || ''} required/>
					<PasswordInputText name="password" placeholder="Password" onChange={handleChange} value={values.password || ''} required/>
					<ButtonPrimary className={`${styles.ButtonPrimary} `} text="Login" type="submit"/>
					{errors.message && (
						<p className={`${styles.ErrorMessage}`}>{errors.message}</p>
					)}
					<p className={`mt-5 mb-3 text-muted`}>© Mary Grant 2021</p>
				</form>
			</main>
		</div>
	</>
}
