import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost} from "../HttpClient";
import {Licence} from "./domain/Licence";

export class LicenceClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/licences`;
    }

    async licences() {
        let response = await httpGet(this.url);
        if (response.status === 200) {
            let data = response.data as Licence[];
            return data.sort((a, b) => a.username.localeCompare(b.username));
        }
        return [];
    }

    async createLicence(username: String) {
        await httpPost(this.url, {
            username: username
        });
    }

    async disableLicence(licenceId: String) {
        await httpPost(`${this.url}/${licenceId}/disable`, {});
    }

    async enableLicence(licenceId: String) {
        await httpPost(`${this.url}/${licenceId}/enable`, {});
    }

    async deleteLicence(licenceId: String) {
        await httpDelete(`${this.url}/${licenceId}`, {});
    }
}
