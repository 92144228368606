import React, {useContext, useState} from 'react';

const Context = React.createContext({});

function StoreProvider(props) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    let values = {
        query,
        setQuery,
        results,
        setResults
    };
    return <Context.Provider value={values} {...props} />
}

function useStore() {
    return useContext(Context);
}

export { StoreProvider, useStore }
