import useForm from '../../hooks/useForm';
import FormInputText from '../Symbols/FormInputText';
import ButtonPrimary from '../Symbols/ButtonPrimary';
import React, {useEffect} from 'react';
import {navigate} from "@reach/router";
import ButtonSecondary from "../Symbols/ButtonSecondary";
import {Sdk} from "../../modules/sdk/Sdk";
import styles from "./Licences.module.css";

export default function AddLicence() {
    const {values, handleChange, handleSubmit} = useForm(onFormSubmit);

    useEffect(() => {
        (async () => {

        })();
    }, []);

    async function onFormSubmit() {
        await Sdk.licenceClient.createLicence(values.username);
        await navigate('/licences');
    }

    async function navigateBack() {
        await navigate('/licences');
    }

    return <>
        <form onSubmit={handleSubmit}>
            <div className={`container`}>
                <div className={`row justify-content-center`}>
                    <div className={`col-md-8 col-12`}>
                        <h1>Assign a new licence</h1>
                        <p>This will create a new licence ID and assign it to the provided username</p>
                        <FormInputText name="username" placeholder="Username" onChange={handleChange} value={values.username || ''} required/>
                    </div>
                </div>
                <div className={`row justify-content-center`}>
                    <div className={`col-md-8 col-12`}>
                        <div className={`${styles.ActionButtons} text-end`}>
                            <ButtonSecondary text="Cancel" type="button" onClick={navigateBack}/>
                            <ButtonPrimary className={`${styles.AssignButton}`} text="Assign" type="submit"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}
