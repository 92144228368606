import {UserClient} from "./UserClient";
import {SdkConfig} from "./SdkConfig";
import {LicenceClient} from "./LicenceClient";

export class ForecastSdk {
    isInitialised: boolean
    userClient: UserClient;
    licenceClient: LicenceClient;

    constructor(config: SdkConfig) {
        this.isInitialised = false;
        this.userClient = new UserClient(config);
        this.licenceClient = new LicenceClient(config);
    }

    async initialise() {
        this.isInitialised = true;
    }
}

let config = process.env.REACT_APP_ENVIRONMENT === 'PROD' ? SdkConfig.local() : SdkConfig.prod();
export const Sdk = new ForecastSdk(config);
