import axios from "axios";
import Auth from "./Auth";
import {navigate} from "@reach/router";

export async function httpGet(url) {
    let config = defaultConfig();
    config.headers['Content-Type'] = 'application/json';
    return await axios.get(url, config);
}

export async function httpPut(url, values) {
    return await axios.put(url, values, defaultConfig());
}

export async function httpPost(url, values) {
    return await axios.post(url, values, defaultConfig());
}

export async function httpDelete(url, values) {
    let config = defaultConfig(values);
    config.headers['Content-Type'] = 'application/json';
    return await axios.delete(url, config);
}

function defaultConfig(data) {
    let config = {
        headers: {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache'
        },
        data : data
    };
    config.headers['X-Api-Key'] = '9PE1Sz9rmx7ilj8ic41u18hx9nTPdUBT2k7Cywd3';
    return config;
}

axios.interceptors.response.use((response) => {
    return response;
}, async function (error) {

    if (error.response.status === 401) {
        await Auth.logout();
        await navigate('/sign-in');
    }
    if (error.response.status.toString().startsWith("3")) {
        return Promise.resolve(error.response);
    }
    return Promise.reject(error);
});
