export class SdkConfig {
    apiUrl: string;

    static prod() {
        return Builder.of().build();
    }

    static local() {
        return Builder.of().localApi().build();
    }

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }
}

export class Builder {
    private _apiUrl: string;

    public static of() {
        return new Builder();
    }

    constructor() {
        this._apiUrl = "https://api.marygrantforecast.com";
    }

    localApi() {
        this._apiUrl = "http://localhost:8080";
        return this;
    }

    apiUrl(apiUrl: string) {
        this._apiUrl = apiUrl;
        return this;
    }

    build() {
        return new SdkConfig(this._apiUrl);
    }
}
