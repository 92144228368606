import React, {useEffect, useState} from 'react';
import styles from './Licences.module.css'
import ButtonPrimary from '../Symbols/ButtonPrimary';
import {navigate} from "@reach/router";
import {Sdk} from "../../modules/sdk/Sdk";
import ButtonSecondary from "../Symbols/ButtonSecondary";


export default function Licences() {
    const [licences, setLicences] = useState([]);
    const [licenceToDelete, setLicenceToDelete] = useState(null);

    useEffect(() => {
        (async () => {
            setLicences(await Sdk.licenceClient.licences());
        })()
    }, []);

    async function addLicence() {
        await navigate('/new-licence');
    }

    async function disableLicence(licenceId) {
        await Sdk.licenceClient.disableLicence(licenceId);
        setLicences(await Sdk.licenceClient.licences());
    }

    async function enableLicence(licenceId) {
        await Sdk.licenceClient.enableLicence(licenceId);
        setLicences(await Sdk.licenceClient.licences());
    }

    async function deleteLicence() {
        if (licenceToDelete !== null) {
            await Sdk.licenceClient.deleteLicence(String(licenceToDelete));
            setLicences(await Sdk.licenceClient.licences());
            setLicenceToDelete(null);
        }
    }

    return <>
        <div className={`container`}>
            <div className={`row justify-content-center`}>
                <div className={`col-md-4 col-4`}>
                    <h1 className={`${styles.LicencesTitle}`}>Licences</h1>
                </div>
                <div className={`col-md-4 col-8`}>
                    <div className={`text-end`}>
                        <ButtonPrimary className={`${styles.AddLicenceButton}`} text="Add new licence" type="button" onClick={addLicence}/>
                    </div>
                </div>
            </div>
            <div className={`row justify-content-center`}>
                <div className={`col-md-8 col-12`}>
                    <h4 className={`${styles.Header}`}>Existing licence holders</h4>
                </div>
            </div>
            {licences && licences.length > 0 && <>
                {licences.map((licence, index) => {
                    return <div key={index} className={`row justify-content-center`}>
                        <div className={`col-md-4 col-6`}>
                            <p>{licence.username}&nbsp;&nbsp;({licence.licence})</p>
                        </div>
                        <div className={`col-md-4 col-5 text-end`}>
                            {licence.enabled ?
                                <ButtonSecondary className={`${styles.DisableButton}`} text="Disable" type="button" onClick={async () => {
                                    await disableLicence(licence.licence);
                                }}/>
                                :
                                <ButtonSecondary className={`${styles.DisableButton}`} text="Enable" type="button" onClick={async () => {
                                    await enableLicence(licence.licence);
                                }}/>
                            }

                            <ButtonSecondary className={`${styles.DeleteButton}`} text="Delete" type="button" onClick={() => {
                                setLicenceToDelete(licence.licence)
                            }
                            } data-bs-toggle="modal" data-bs-target="#deleteLicence" />
                        </div>
                    </div>
                })}
            </>
            }
        </div>
        <div className={"modal fade"} id="deleteLicence" tabIndex="-1" aria-labelledby="deleteLicenceLabel" aria-hidden="true">
            <div className={"modal-dialog"}>
                <div className={"modal-content"}>
                    <div className={"modal-header"}>
                        <h5 className={"modal-title"} id="deleteLicenceLabel">Are you sure?</h5>
                        <button type="button" className={"btn-close"} data-bs-dismiss="modal" aria-label="Cancel"/>
                    </div>
                    <div className={"modal-body"}>
                        This action cannot be undone
                    </div>
                    <div className={"modal-footer"}>
                        <button type="button" className={"btn btn-secondary"} data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className={"btn btn-danger"} data-bs-dismiss="modal" onClick={deleteLicence}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}
